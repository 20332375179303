<template>
  <div class="home">
    <!-- 跑马灯 -->
    <el-carousel :height="screenHeight" arrow="never" :interval='5000' @change="onBannerChange">
      <el-carousel-item v-for="banner in bannerList" :key="banner.id">
        <a class="banner-a" :href="banner.jumpUrl" v-if="banner.jumpUrl" target="_blank">
          <img :height="screenHeight" :src="banner.imgUrl" ref="img" />
        </a>
        <div v-else-if="banner.remark === '功夫茶'" class="banner-a" style="cursor:pointer" @click="toGongfu()">
          <img :src="banner.imgUrl"/>
        </div>
        <div v-else class="banner-a" >
          <img :src="banner.imgUrl"/>
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 搜索 -->
   <!-- <div class="search-box" style="margin: 20px auto 0;">
     <input v-model="searchText" id="search" type="text" class="search-input" placeholder="输入关键字">
     <button type="button" class="search-btn" @click="Search()">查询</button>
   </div> -->

   <!-- 公告 -->
   <div class="gonggaoBox">
    <div class="gonggaoList">
      <div class="tabBox">
        <div :class="isActive === 1 ? 'activeClass' : ''" @mousemove="mouseEnter(1)" class="text_title" style="font-size: 22px;font-weight:bold;margin-bottom: 5px">最新公告</div>
        <div class="miduleSt"/>
        <div :class="isActive === 2 ? 'activeClass' : ''" @mousemove="mouseEnter(2)" class="text_title" style="font-size: 22px;font-weight:bold;margin-bottom: 5px">学会动态</div>
      </div>
      <div class="messageBox" v-if="isActive === 1">
          <div class="messageChild" v-for="item in messageList" :key="item.id" @click="toNotice(item,3)">{{`${item.title}`}}</div>
      </div>
      <div class="messageBox" v-if="isActive === 2">
          <div class="messageChild" v-for="item in xuehuiList" :key="item.id" @click="toNotice(item,2)">{{`${item.title}`}}</div>
      </div>
      <div class="more" v-if="isActive === 1" @click="toMenuRouter(3)">更多</div>
      <div class="more" v-if="isActive === 2" @click="toMenuRouter(2)">更多</div>
    </div>
   </div>

    <!--中间链接-->
    <ul class="index-list">
      <li>
        <!--<img src="Upload/Pic/2019926155055pic1.jpg" id="myimg1" class="list-pic" />
        <a href="operations.aspx" class="list-a">
          <span class="cn-tit">业务中心</span>
          <span class="en-tit">Operations Centre</span>
        </a>-->

        <img src="../../assets/images/new_2022/img1.jpg" class="list-pic" />
        <div class="list-a">

          <el-dropdown trigger="click" placement="top">
            <div style="width: 170px; height: 60px">
              <el-button style="margin-top:20px" type="text" >
                <span class="cn-tit">业务中心</span>
              </el-button>
<!--              <span class="en-tit">Operations Centre</span>-->
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="link_menu_title" @click.native="toMenuRouter(0)">党建活动</el-dropdown-item>
              <el-dropdown-item class="link_menu_title" @click.native="toMenuRouter(1)">公益活动</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <a href="literature.aspx?type=1" class="list-a-show list-a-show-0">表单下载</a>
        </div>
      </li>
      <li>
        <img src="../../assets/images/pic2.jpg" id="myimg2" class="list-pic" />
        <div class="list-a">
          <el-dropdown placement="top">
            <div style="width: 170px; height: 60px">
              <el-button style="margin-top:20px" type="text" >
                <span class="cn-tit">学术活动</span>
              </el-button>
<!--              <span class="en-tit">Academic events</span>-->
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="toMenuRouter(2)">学术活动</el-dropdown-item>
              <el-dropdown-item @click.native="toMenuRouter(3)">通知公告</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>
      </li>
      <!-- <li>
        <img src="../../assets/images/pic3.jpg" id="myimg3" class="list-pic" />
        <div  class="list-a">
          <el-dropdown placement="top">
            <div style="width: 260px; height: 90px">
              <el-button style="margin-top:25px" type="text" >
                <router-link to="/naocuzhong"> <span class="cn-tit">脑卒中救治中心</span></router-link>
              </el-button>
              <span class="en-tit">Stroke Rescue Center</span>
            </div>
          </el-dropdown>
        </div>
      </li> -->
      <!-- <li>
        <img src="../../assets/images/pic4.jpg" id="myimg4" class="list-pic" />
        <div  class="list-a">
          <el-dropdown placement="top">
            <div style="width: 260px; height: 90px">
              <el-button style="margin-top:10px; line-height:0.9" type="text" >
                <router-link to="/hongshouhuan">
                  <span class="cn-tit">神经系统疾病</span><br/>
                  <span class="cn-tit">泛长三角联盟</span>
                 </router-link>

              </el-button>
              <span class="en-tit">Pan-Yangtze River Delta Alliance</span>
            </div>
          </el-dropdown>
        </div>
      </li> -->
      <!-- <li>
        <img src="../../assets/images/new_2022/img4.jpg" id="myimg5" class="list-pic" />

        <div  class="list-a" style="width:230px">
          <el-dropdown placement="top">
            <div style=" height: 60px;font-size:12px">
              <el-button style="margin-top:10px;padding-left: 15px"  type="text" >
                 <span class="cn-tit">中国神经病学论坛</span>
                 <span class="cn-tit" style="margin-top: 5px">神经系统疾病·泛长三角联盟</span>
              </el-button>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="link_menu_title" @click.native="toMenuURL('https://www.shanghaistroke-cnf.com/#/')">中国神经病学论坛</el-dropdown-item>
              <el-dropdown-item class="link_menu_title" @click.native="toMenuRouter(2)">泛长三角联盟</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </li> -->
      <li>
        <img src="../../assets/images/new_2022/img5.jpg" id="myimg6" class="list-pic" />
        <div  class="list-a" >
          <el-dropdown placement="top">
            <div style="width: 170px; height: 60px;">
              <el-button style="margin-top:20px" type="text" >
                  <span class="cn-tit" style="font-size:16px">中青年托举项目</span>
              </el-button>
<!--              <span class="en-tit">Pan-Yangtze River Delta Alliance</span>-->
            </div>
            <!-- <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="link_menu_title" @click.native="toMenuRouter(2)">中国血管性认知障碍协作联盟</el-dropdown-item>
            </el-dropdown-menu> -->
          </el-dropdown>
        </div>
      </li>
      <li>
        <img src="../../assets/images/new_2022/img6.png" id="myimg6" class="list-pic" />
        <div  class="list-a" >
          <el-dropdown placement="top">
            <div style="width: 170px; height: 60px; cursor: pointer;" >
              <el-button style="margin-top:10px" type="text"  @click="toVica()">
                  <span class="cn-tit" style="font-size:16px">中国血管性认知</span>
                    <span class="cn-tit" style="font-size:16px;margin-top: 5px;">障碍协作联盟</span>
              </el-button>
<!--              <span class="en-tit">Pan-Yangtze River Delta Alliance</span>-->
            </div>
            
          </el-dropdown>
        </div>
      </li>
      <!-- <li>
        <img src="../../assets/images/pic6.jpg" id="myimg6" class="list-pic" />
        <div class="list-a">
          <el-dropdown placement="top">
            <div style="width: 260px; height: 90px">
              <el-button style="margin-top:25px" type="text" >
                <span class="cn-tit">会员之家</span>
              </el-button>
              <span class="en-tit">Family Of Members</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="toMenuRouter(4)">文献查找</el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>
          <a href="literature2.aspx?type=1" class="list-a-show list-a-show-0">文献查找</a>
        </div>
      </li> -->
    </ul>
    <div class="videoBox">
      <video class="videoS" width="450" height="450"

             autoplay="autoplay" loop="loop" muted="muted"
             preload="none" controls="controls">
        <source src="https://projectimg-1256680128.cos.ap-beijing.myqcloud.com/amis/20231103/56a5444169504a149d364d919b99fa18.M4V"/>
      </video>
      <div class="highlightsBox">
        <div class="highlightsText1">“世界卒中日”公益宣传活动</div>
        <div class="desText">2023年10月29日是第18个世界卒中日，今年的主题是“卒中：防重于治””、“卒中识别早，救治效果好”。为普及脑卒中防治知识，上海卒中学会在机场、轨道交通核心站点及地面公交站点投放“卒中：防重于治”公益形象，宣传卒中危害与防病知识，提升社会公众对脑卒中防治的关注度和参与度。</div>
      </div>
    </div>
    <!-- <div class="videoBox">
            <video class="videoS" width="450" height="450"

                   autoplay="autoplay" loop="loop" muted="muted"
                   preload="none" controls="controls">
                <source src="https://yhjfiles.doctorworking.cn/30S-HD.mp4"/>
            </video>
            <div class="highlightsBox">
                <div class="highlightsText1">第四届华山认知与脑疾病国际论坛</div>
                <div class="desText">第四届华山认知与脑疾病国际论坛暨科技创新2030-脑科学与类脑研究项目启动会于5月14日在上海虹桥宾馆正式拉开帷幕。本次论坛邀请认知与脑疾病领域多位院士、长江学者、杰 出青年和知名专家，聚焦认知与脑科学的新理念进行专题报告与学术交流。</div>
            </div>
        </div><div style="margin-top: 50px; margin-bottom: 50px">

    </div>

    <div style="margin-top: 50px; margin-bottom: 50px" />
    <div class="videoBox">
      <video class="videoS" width="450" height="450"

             autoplay="autoplay" loop="loop" muted="muted"
              preload="none" controls="controls">
              <source src="https://yhjfiles.doctorworking.cn/10.29-31_30s%E8%8A%B1%E7%B5%AE%E9%AB%98%E6%B8%85.mp4"/>
      </video>
      <div class="highlightsBox">
        <div class="highlightsText">上海卒中学会第二届学术年会</div>
        <div class="desText">上海卒中学会第二届学术年会暨第十五届中国神经病学论坛、第二届神经系统疾病·泛长三角论坛于2021年10月29日至10月31日在上海静安洲际酒店召开。会议采用线下和线上相结合的模式，国内神经系统领域的专家、学者们齐聚一堂，融合国际学术前沿和新型诊疗技术进行了深入交流，为参会者提供一个内容丰富的学术盛宴。</div>
      </div>
    </div> -->

    <div style="margin-top: 50px; margin-bottom: 50px">

    </div>

  </div>
</template>

<script>
  import dict from '@/assets/json/dictionaries.json'
  import mixs from "@/mixs"
  import {getSwBranchList, getSwBanner, getSwNotice, getSwLogo, getSwscrollchart, getSwadvertisement, getSwArticle} from '@/http/request'
  import {getPhotos} from "../../http/request";
  export default {
    name: "Home",
    inject: ['language'],
    mixins: [mixs],
    data() {
      return {
        searchText:'', // 搜索框
        disabled:false,
        bannerTitle:'',
        carouselHeight: '100%',
        bannerList: [],
        swBranchList: [],
        noticeList: [],
        logoList: [],
        photoList:[],
        scrollchartList: [],
        adList: [],
        swBranchUrl: null,
        dict,
        screenHeight: (document.body.clientWidth /4).toString() + 'px',
        routerObj:[
          {"to":"/contentList/1440953594898554882","path":["业务中心","党建活动"],"evtObj":{"id":"1440953594898554882","level":1,"nameCh":"党建活动","nameEn":"","type":2,"parent":"1387007590828457985","status":1,"urlCh":"","urlEn":"","weight":8,"createDate":"2021-04-27 19:35:54","updateDate":"2021-09-23 16:18:32","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0},"menu":{"id":"1387007590828457985","level":0,"nameCh":"业务中心","nameEn":"","type":0,"parent":"0","status":1,"urlCh":"","urlEn":"","weight":7,"createDate":"2021-04-27 19:35:54","updateDate":"2021-04-27 21:09:06","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0,"index":2,"children":[{"id":"1440953594898554882","level":1,"nameCh":"党建活动","nameEn":"","type":2,"parent":"1387007590828457985","status":1,"urlCh":"","urlEn":"","weight":8,"createDate":"2021-04-27 19:35:54","updateDate":"2021-09-23 16:18:32","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0}]}},
          {"to":"/contentList/1440953671620763650","path":["业务中心","公益活动"],"evtObj":{"id":"1440953671620763650","level":1,"nameCh":"公益活动","nameEn":"","type":2,"parent":"1387007590828457985","status":1,"urlCh":"","urlEn":"","weight":9,"createDate":"2021-04-27 19:35:54","updateDate":"2021-09-23 16:18:32","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0},"menu":{"id":"1387007590828457985","level":0,"nameCh":"业务中心","nameEn":"","type":0,"parent":"0","status":1,"urlCh":"","urlEn":"","weight":7,"createDate":"2021-04-27 19:35:54","updateDate":"2021-04-27 21:09:06","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0,"index":2,"children":[{"id":"1440953671620763650","level":1,"nameCh":"公益活动","nameEn":"","type":2,"parent":"1387007590828457985","status":1,"urlCh":"","urlEn":"","weight":9,"createDate":"2021-04-27 19:35:54","updateDate":"2021-09-23 16:18:32","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0}]}},
          {"to":"/contentList/1387008031733694466","path":["学术活动","学术活动"],"evtObj":{"id":"1387008031733694466","level":1,"nameCh":"学术活动","nameEn":"","type":2,"parent":"1387007950720712705","status":1,"urlCh":"","urlEn":"","weight":12,"createDate":"2021-04-27 19:35:54","updateDate":"2021-09-23 16:18:32","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0},"menu":{"id":"1387007950720712705","level":0,"nameCh":"学术活动","nameEn":"","type":0,"parent":"0","status":1,"urlCh":"","urlEn":"","weight":9,"createDate":"2021-04-27 19:37:19","updateDate":"2021-04-27 21:09:06","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0,"index":3,"children":[{"id":"1387008031733694466","level":1,"nameCh":"学术活动","nameEn":"","type":2,"parent":"1387007950720712705","status":1,"urlCh":"","urlEn":"","weight":12,"createDate":"2021-04-27 19:35:54","updateDate":"2021-09-23 16:18:32","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0}]}},
          {"to":"/contentList/1387008073009840130","path":["学术活动","通知公告"],"evtObj":{"id":"1387008073009840130","level":1,"nameCh":"通知公告","nameEn":"","type":2,"parent":"1387007950720712705","status":1,"urlCh":"","urlEn":"","weight":11,"createDate":"2021-04-27 19:35:54","updateDate":"2021-04-27 21:09:06","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0},"menu":{"id":"1387007950720712705","level":0,"nameCh":"学术活动","nameEn":"","type":0,"parent":"0","status":1,"urlCh":"","urlEn":"","weight":9,"createDate":"2021-04-27 19:37:19","updateDate":"2021-04-27 21:09:06","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0,"index":3,"children":[{"id":"1387008073009840130","level":1,"nameCh":"通知公告","nameEn":"","type":2,"parent":"1387007950720712705","status":1,"urlCh":"","urlEn":"","weight":11,"createDate":"2021-04-27 19:35:54","updateDate":"2021-04-27 21:09:06","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0}]}},
          {"to":"/contentList/1387008837983780865","path":["会员之家","文献查找"],"evtObj":{"id":"1387008837983780865","level":1,"nameCh":"文献查找","nameEn":"","type":2,"parent":"1387008787975094273","status":1,"urlCh":"","urlEn":"","weight":14,"createDate":"2021-04-27 19:39:41","updateDate":"2021-04-27 21:09:06","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0},"menu":{"id":"1387008787975094273","level":0,"nameCh":"会员之家","nameEn":"","type":0,"parent":"0","status":1,"urlCh":"","urlEn":"","weight":13,"createDate":"2021-04-27 19:40:39","updateDate":"2021-04-27 21:09:06","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0,"index":4,"children":[{"id":"1387008837983780865","level":1,"nameCh":"文献查找","nameEn":"","type":2,"parent":"1387008787975094273","status":1,"urlCh":"","urlEn":"","weight":14,"createDate":"2021-04-27 19:39:41","updateDate":"2021-04-27 21:09:06","creator":"1067246875800000001","updater":"1067246875800000001","isDel":0}]}}
        ],
        messageList:[],
        isActive: 1
      }
    },
    created() {
      this.getSwBranchList();
      this.getSwBanner();
      this.getSwNotice();
      this.getSwLogo();
      this.getSwadvertisement();
      this.getSwscrollchart();
      this.getPhotoList();
      this.getSwArticleList()
      this.getXuehuiList()
    },
    computed: {
      getLanguage() {
        return this.language === 'zh' ? 0 : 1
      },
      scrollchartListByStatus(){
        return this.scrollchartList.filter(v=>v.status === 1)
      }
    },
    mounted() {
      window.addEventListener("resize", () => {
        this.screenHeight = (document.body.clientWidth /4).toString() + 'px'
      })
    },
    methods: {
      toVica() {
        window.open("https://vica.shanghaistroke.org.cn/")
      },
      // 切换tab
      mouseEnter(val) {
        this.isActive = val
      },
      // 公告点击每条   先将公告数据存进缓存，再跳页
      toNotice(obj, type) {
          localStorage.setItem('home_notice',JSON.stringify(obj))
          this.$router.push({
              path: this.routerObj[type].to,
              query: {
                  paths: JSON.stringify(this.routerObj[type].path),
                  menu: JSON.stringify(this.routerObj[type].menu)
              }
          })
      },
      // 功夫茶页面
      toGongfu() {
        const newList = this.xuehuiList.filter(item => item.id === '1475080708448702466');
        const obj = newList.length ? newList[0] : {}
        localStorage.setItem('home_notice',JSON.stringify(obj))
          this.$router.push({
              path: this.routerObj[2].to,
              query: {
                  paths: JSON.stringify(this.routerObj[2].path),
                  menu: JSON.stringify(this.routerObj[2].menu)
              }
          })
      },
      toMenuRouter(v){
        this.$router.push({
            path: this.routerObj[v].to,
            query: {
                paths: JSON.stringify(this.routerObj[v].path),
                menu: JSON.stringify(this.routerObj[v].menu)
            }
        })
      },
      toMenuURL(url){
        window.open(url)
      },
      // 搜索框
      Search() {
        // 将输入框内容存到全局然后跳到文献查找页面
        this.$store.commit('setSearchText', this.searchText);
        this.$router.push({
            path: this.routerObj[4].to,
            query: {
                paths: JSON.stringify(this.routerObj[4].path),
                menu: JSON.stringify(this.routerObj[4].menu)
            }
        })
      },
      // 获取历史图片
      getPhotoList(){
        getPhotos().then(({data})=>{
          // console.log(data.data)
          this.photoList = data.data
        })
      },
      onBannerChange(index){
        this.bannerTitle =this.bannerList[index]['remark']
      },
      handleClickCard() {

      },
      // 获取banner
      getSwBanner() {
        getSwBanner({language: this.getLanguage }).then(({data}) => {
          if(data.code === 0) {
            this.bannerList = data.data.list
            this.bannerTitle = data.data.list[0]['remark']
          }
        })
      },

      // 获取新闻
      getSwArticleList() {
        const obj = {
          navId: '1387008073009840130',
          page: 1,
          limit: 10,
          lanuage: 0,
        }
        getSwArticle(obj).then(({data}) => {
          if(data.code === 0) {
            const list = data.data.list;
            if(list.length > 3) {
              this.messageList = list.slice(0,3)
            }else {
              this.messageList = list;
            }
          }
        })
      },
      // 获取学会动态
      getXuehuiList() {
        const obj = {
          navId: '13870080317336944662',
          page: 1,
          limit: 10,
          lanuage: 0,
        }
        getSwArticle(obj).then(({data}) => {
          if(data.code === 0) {
            const list = data.data.list;
            if(list.length > 3) {
              this.xuehuiList = list.slice(0,3).map(item => {
                return {
                  ...item,
                  title: `${item.navName} | ${item.title}`
                }
              })
            }else {
              this.xuehuiList = list;
            }
          }
        })
      },

      // 获取公告
      getSwNotice() {
        getSwNotice({lanuage: this.getLanguage, limit: 3, page: 1}).then(({data}) => {
          if(data.code === 0) {
            this.noticeList = data.data.list
          }
        })
      },

      // 获取logo
      getSwLogo() {
        getSwLogo({lanuage: this.getLanguage, limit: 4}).then(({data}) => {
          if(data.code === 0) {
            this.logoList = data.data.list
          }
        })
      },

      // 图片滚动区域
      getSwscrollchart() {
        getSwscrollchart({language: this.getLanguage }).then(({data}) => {
          if(data.code === 0) {
            this.scrollchartList = data.data.list
          }
        })
      },

      // 获取专科分会列表
      getSwBranchList() {
        getSwBranchList({limit: 500, page: 1, language: this.getLanguage }).then(({data}) => {
          if(data.code === 0) {
            this.swBranchList = data.data.list
          }
        })
      },

      // 获取广告
      getSwadvertisement() {
        getSwadvertisement({language: 0 }).then(({data}) => {
          if(data.code === 0) {
            this.adList = data.data.list
          }
        })
      },

      // 点击logo
      handlerClickLogo(logo) {
        if(logo.jumpUrl) {
          window.open(logo.jumpUrl)
        }
      },

      // 点击图片滚动区域
      hanlerClickScrollChart(obj) {
        if(obj.type !== 0 && obj.jumpUrl) {
          window.open(obj.jumpUrl)
        }
      },

      handlerClickAd(url) {
        if(url) {
          window.open(url)
        }
      },

      // 跳转专科分会
      toSwBranch() {
        window.open(this.swBranchUrl)
      }
    }
  }
</script>
<style lang="less">
.home {
  .banner-title {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    height: 40px;
    color: #3f3f3f;
  }
  .banner-a {
    display: block;
    width: 100%;
    height: 100%;
    & > div {
      width: 100%;
      height: 100%;
    }
  }

  .home-notice {
    width: 933px;
    margin: 10px auto;
    box-sizing: border-box;
    .home-notice-title {
      font-size: 20px;
      color: rgba(20, 153, 220, 1);
      font-weight: 400;
    }
    .home-notice-content {
      margin-top: 10px;
      padding: 0px 30px 32px;
      background: #d8ecfa;
      position: relative;
      ul {
        li {
          border-bottom: 1px solid #ddd;
          padding: 15px 0 10px;
          display: flex;
          align-items: center;
          div {
            width: max-content;
            max-width: 893;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 400;
            color: #555;
          }
          button {
            width: 40px;
          }
        }
      }
      .home-notice-content-more {
        display: flex;
        align-items: center;
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 12px;
        color: rgba(20, 153, 220, 1);
        span {
          cursor: pointer;
        }
        img {
          width: 12px;
        }
      }
    }
  }
  .home-cards {
    display: flex;
    justify-content: space-between;
    width: 933px;
    margin: 54px auto;
    box-sizing: border-box;
    position: relative;
    .home-card {
      width: 205px;
      height: 292px;
      padding: 50px 0;
      box-sizing: border-box;
      background: #fff;
      box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
      position: relative;
      overflow: hidden;
      transition: transform 0.3s;
      img {
        display: block;
        margin: 0 auto;
        width: 110px;
        height: 110px;
      }
      .home-card-title {
        margin-top: 55px;
        display: block;
        width: 100%;
        text-align: center;
        font-size: 15px;
        color: #3f3f3f;
      }
      .home-card-action {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        transform: translateY(100%);
        transition: transform 0.3s;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        font-size: 14px;
        color: #fff;
        background: rgba(0, 0, 0, 0.65);
        cursor: pointer;
        &:hover {
          color: #eee;
        }
      }
      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        .home-card-action {
          transform: translateY(0);
          transition: transform 0.3s;
        }
      }
      &::after {
        width: 100%;
        height: 8px;
        background: linear-gradient(90deg, #1499dc, #283885);
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }
  }
  .home-image-and-text {
    width: 933px;
    margin: 54px auto;
    .home-image-and-text-cell {
      height: 450px;
      // overflow: hidden;
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      .home-video-and-img {
        width: 450px;
        height: 450px;
        justify-content:center;//子元素水平居中
        align-items:center;//子元素垂直居中
        display:-webkit-flex;
        overflow: hidden;
        box-sizing: border-box;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
        border: 4px solid;
        border-image: -webkit-linear-gradient(#1499dc, #283885) 30 30;
        border-image: -moz-linear-gradient(#1499dc, #283885) 30 30;
        border-image: linear-gradient(#1499dc, #283885) 30 30;
        video,
        img {
          height: auto;
          max-width: 100%;
          outline: none;
        }
      }
      &.image-text {
        .home-image-and-text-cell-text {
          padding-left: 80px;
        }
        &.cursor {
          cursor: pointer;
        }
      }
      &.text-image {
        .home-image-and-text-cell-text {
          padding-right: 80px;
        }
        &.cursor {
          cursor: pointer;
        }
      }
      & > div {
        &.home-image-and-text-cell-text {
          flex: 1;
        }
        overflow: hidden;
        &.home-image-and-text-cell-text {
          box-sizing: border-box;
          span {
            margin-bottom: 50px;
            display: block;
            font-size: 34px;
            font-weight: bold;
            color: #123577;
            line-height: 45px;
          }
          p {
            letter-spacing: 2px;
            text-align: justify;
            font-size: 18px;
            font-weight: 400;
            color: #3f3f3f;
            line-height: 44px;
          }
        }
        video {
          width: 100%;
        }
      }
    }
  }
  .home-select-links {
    width: 100%;
    height: 74px;
    margin-bottom: 54px;
    background: linear-gradient(90deg, #1499dc, #283885);
    & > div {
      width: 933px;
      margin: 0 auto;
      padding-top: 40px;
      padding-bottom: 40px;
      display: flex;
      align-items: center;
      font-size: 26px;
      font-weight: 400;
      color: #def1fc;
      & > div {
        flex: 1;
      }
      & > span {
        flex: 1;
      }
    }
  }
  .home-ads {
    width: 933px;
    margin: 54px auto;
    overflow: hidden;
    & > div {
      width: 25%;
      height: 180px;
      float: left;
      padding: 20px;
      margin-bottom: 20px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      &.hover {
        cursor: pointer;
      }
    }
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .el-button {
    padding: 0 20px;
    color: rgb(198, 54, 54);
  }
}
.el-select-dropdown__wrap,
.el-popper {
  max-height: 182px;
}
.search-box {
  width: 500px;
  margin: -20px auto 50px;
  overflow: hidden;
}
.index-list {
  width: 1100px;
  margin: 80px auto;
  margin-top: 40px;
  margin-bottom: 0px;
  overflow: hidden;
  clear: both;
  padding: 10px;
}
.index-list li {
  position: relative;
  width: 250px;
  float: left;
  margin-bottom: 100px;
  margin-left: 20px;
}
.list-pic {
  display: block;
  width: 240px;
  height: 150px;
  border-radius: 4px;
  box-shadow: 6px 6px 6px 6px rgb(0 0 0 / 30%);
}
ol, ul {
   list-style: none;
 }
.list-a {
  position: absolute;
  display: block;
  width: 170px;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 9%);
  margin: auto;
  left: -10px;
  right: 0;
  bottom: -45px;
  text-align: center;
  // padding-top: 20px;
  box-sizing: border-box;
}
.list-a span.cn-tit {
  font-size: 16px;
  color: #383a3c;
}

.list-a span {
  display: block;
}
.list-a .list-a-show {
  position: relative;
  height: 60px;
  line-height: 40px;
  top: -120px;
  padding: 10px 50px;
  background-color: rgba(255,255,255,.6);
  box-sizing: border-box;
  transition: top .6s;
  opacity: 0;
}
.search-box {
  width: 500px;
  margin: -20px auto 50px;
  overflow: hidden;
}
.search-input {
  float: left;
  width: 400px;
  border: 1px solid #9c9797;
  border-right: none;
  height: 45px;
  padding: 5px 8px;
  box-sizing: border-box;
  font-size: 16px;
}
.search-btn {
  float: left;
  height: 45px;
  width: 100px;
  color: #fff;
  font-size: 16px;
  background-color: #014DA3;
  cursor: pointer;
}
.el-select-dropdown__wrap, .el-popper {
  min-height: 30px;
  width: 170px;
  background-color: rgba(255,255,255,.6);
  opacity: 1;
  margin-bottom: 8px;
}
.el-dropdown-menu__item {
  line-height: 60px;
  font-size: 16px;
  text-align: center;
}
.el-dropdown-menu {
  padding: 0px 0px !important;
}
.list-a:hover {
  background-color: #014DA4;
  color: #fff;
  .cn-tit {
    color: #fff !important;
  }
  .en-tit{
      color: #fff !important;
    }
}
.el-dropdown-menu .el-popper:hover + #App .list-a {
    background-color: #014DA4;
    color: #fff;
    .cn-tit {
        color: #fff !important;
    }
}
.videoBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.gonggaoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gonggaoList {
  box-sizing: border-box;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 50px;
  width: 930px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dddddd;
  border-radius: 20px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
}
.messageBox {
  box-sizing: border-box;
  padding: 10px 50px;
  width: 100%;
  max-height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.messageChild {
  width: 100%;
  padding: 15px 0;
  cursor: pointer;
  border-bottom: 1px solid #dddddd;
}
.more {
  width: 100%;
  text-align: end;
  margin-right: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #014DA3;
  font-size: 16px;
}
.highlightsBox {
  height: 450px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 26px;
}
.highlightsText {
  margin-bottom: 20px;
  color: #123577;
  line-height: 45px;
  font-size: 30px;
  font-weight: bold;
}
.highlightsText1 {
  margin-bottom: 20px;
  color: #123577;
  line-height: 45px;
  font-size: 30px;
  font-weight: bold;
}
.desText {
  margin-top: 20px;
  box-sizing: border-box;
  width: 460px;
  padding: 10px;
  line-height: 30px;
  font-size: 15px;
}
.videoS {
  border-top-color: initial;
  border-top-style: solid;
  border-top-width: 4px;
  border-right-color: initial;
  border-right-style: solid;
  border-right-width: 4px;
  border-bottom-color: initial;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-left-color: initial;
  border-left-style: solid;
  border-left-width: 4px;
  border-image-source: linear-gradient(rgb(20, 153, 220), rgb(40, 56, 133));
  border-image-slice: 30;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-repeat: stretch;
}
.text_title {
  box-sizing: border-box;
  padding-left: 20px;
  // width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}
.activeClass {
  color: #014DA3;
}
.miduleSt {
  width: 3px;
  height: 22px;
  background: #000;
  margin-left: 20px;
}
.tabBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
</style>
